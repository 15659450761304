body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #b6b6b6 #ffffff00;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #ffffff00;
}

*::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 6px;
  border: 0px none #ffffff00;
}

.list-action {
  min-width: 90px;
  display: block;
}

.list-action button {
  margin-right: 5px;
}

.list-action button:last-child {
  margin-right: 0px;
}

.table-head-links {
  display: flex;
  justify-content: space-between;
}

.table-head-links .reload-link {
  margin-top: 15px;
  margin-left: 15px;
}

.table-head-links .add-link {
  margin-top: 15px;
  margin-right: 15px;
}

.table-head-links .add-link .add-icon {
  font-size: 1.2em;
  position: relative;
  top: 4px;
}

.MuiIconButton-root.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.form-error{
  color: #d32f2f;
  display: block;
  text-align: left;
  font-size: 0.8em;
}

.sidebar-treeview > .MuiTreeItem-root > .MuiTreeItem-content {
  flex-direction: row-reverse;
  padding: 12px 4px 12px 12px;
}

.sidebar-treeview > .MuiTreeItem-root > .MuiTreeItem-content > .MuiTypography-root {
  flex-direction: row-reverse;
  padding: 12px 4px 12px 12px;
}

.sidebar-treeview .MuiTreeItem-content {
  flex-direction: row-reverse;
  padding: 4px;
}

.sidebar-treeview .MuiTreeItem-content .MuiTypography-root {
  padding: 4px 4px 4px 0px;
  font-size: 0.8rem;
}

.sidebar-treeview .MuiTreeItem-content .MuiListItemIcon-root{
  min-width: 30px;
} 

.sidebar-treeview > .MuiTreeItem-root > .MuiTreeItem-content > .MuiTreeItem-label .MuiListItemIcon-root {
  min-width: 40px !important;
}

.sidebar-treeview > .MuiTreeItem-root > .MuiTreeItem-content > .MuiTreeItem-label .MuiTypography-root {
  font-size: 1rem !important;
}
